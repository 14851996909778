import React, { useEffect, useState } from 'react'

import { setCookies, useEnv } from '@acorns/web-utils'

import {
  ValidateCredentialsMutation,
  useValidateCredentialsMutation,
} from 'generated/types'

import {
  generateHashedNonce,
  generateNonce,
} from '../utils/generateHashedNonce'
import { AppleIconSvg } from './AppleIconSvg'
import {
  ERROR_TYPES,
  ErrorType,
  SignInWithAppleErrorModal,
} from './SignInWithAppleErrorModal'
import {
  AppleButton,
  AppleButtonContainer,
  CustomTextWrapper,
} from './styled-components'

export const SignInWithApple = () => {
  const { env } = useEnv()
  const [errorType, setErrorType] = useState<ErrorType | null>(null)
  const [suspendedToken, setSuspendedToken] = useState('')

  const [validateCredentials] = useValidateCredentialsMutation({
    onCompleted: (data: ValidateCredentialsMutation) => {
      if (
        data.validateCredentials.__typename === 'AuthSession' &&
        data.validateCredentials
      ) {
        setCookies(data.validateCredentials)
        window.location.href = env.get('defaultRedirect')
        return
      }

      if (
        data.validateCredentials.__typename ===
        'OidcCredentialsNotFoundException'
      ) {
        setErrorType(ERROR_TYPES.ACCOUNT_NOT_LINKED)
        return
      }

      if (data.validateCredentials.__typename === 'UserSuspendedException') {
        setSuspendedToken(data.validateCredentials.suspendedToken)
        setErrorType(ERROR_TYPES.ACCOUNT_SUSPENDED)
        return
      }

      if (data.validateCredentials.__typename === 'DeviceBlockedException') {
        setErrorType(ERROR_TYPES.DEVICE_BLOCKED)
        return
      }

      if (data.validateCredentials.__typename === 'LoginRateLimitedException') {
        setErrorType(ERROR_TYPES.LOGIN_RATE_LIMITED)
        return
      }

      setErrorType(ERROR_TYPES.DEFAULT_ERROR)
    },
  })

  useEffect(function loadSdk() {
    const script = window.document.createElement('script')

    script.src =
      'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js'

    script.onload = async function initializeSdk() {
      const nonce = await generateHashedNonce()
      const crossSiteReturnState = generateNonce()

      window.AppleID.auth.init({
        clientId: env.get('signInWithAppleClientId'),
        scope: 'name email',
        redirectURI: env.get('signInWithAppleRedirectUrl'),
        state: crossSiteReturnState,
        nonce,
        usePopup: true,
      })

      window.document.addEventListener(
        'AppleIDSignInOnSuccess',
        (event: any) => {
          const externalIdToken = event.detail.authorization.id_token
          const returnedState = event.detail.authorization.state

          if (returnedState === crossSiteReturnState) {
            validateCredentials({
              variables: {
                udid: 'test-device-id',
                idToken: externalIdToken,
                nonce,
              },
            })
            return
          }

          setErrorType('DEFAULT_ERROR')
        },
      )

      window.document.addEventListener('AppleIDSignInOnFailure', () => {
        setErrorType('DEFAULT_ERROR')
      })
    }

    window.document.body.appendChild(script)
  }, [])

  const handleCloseModal = () => {
    setErrorType(null)
  }

  return (
    <>
      <AppleButtonContainer>
        <AppleButton
          id="appleid-signin"
          data-color="black"
          data-border="true"
          data-type="sign in"
          data-border-radius="50"
          style={{ width: '100%', height: '44px' }}
        />
        <CustomTextWrapper>
          <AppleIconSvg />
          <span>Continue with Apple</span>
        </CustomTextWrapper>
      </AppleButtonContainer>
      <SignInWithAppleErrorModal
        onClose={handleCloseModal}
        errorType={errorType}
        suspendedToken={suspendedToken}
      />
    </>
  )
}
