export function generateNonce(length = 32): string {
  if (length <= 0) {
    throw new Error('Invalid nonce length')
  }

  const charset =
    '0123456789ABCDEFGHIJKLMNOPQRSTUVXYZabcdefghijklmnopqrstuvwxyz-._'
  const randomValues = new Uint8Array(length)

  crypto.getRandomValues(randomValues)

  const nonce = Array.from(randomValues)
    .map((value) => charset[value % charset.length])
    .join('')

  return nonce
}

export async function generateHashedNonce(length = 32): Promise<string> {
  const nonce = generateNonce(length)

  const encoder = new TextEncoder()
  const data = encoder.encode(nonce)

  const hashBuffer = await crypto.subtle.digest('SHA-256', data)

  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashedNonce = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('')

  return hashedNonce
}
