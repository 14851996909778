import React from 'react'

import { DividerLine, DividerOr, OrDividerContainer } from './styled-components'

export const OrDivider = () => {
  return (
    <OrDividerContainer>
      <DividerLine />
      <DividerOr>or</DividerOr>
      <DividerLine />
    </OrDividerContainer>
  )
}
