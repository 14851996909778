import React, { useEffect } from 'react'

import {
  AnalyticsClientProvider,
  EnvironmentHooksProvider,
} from '@acorns/web-utils'
import { nest } from 'recompose'
import { ThemeProvider } from 'styled-components'

import AccessibilityAnnouncement from 'components/AccessibilityAnnouncements'
import Messages from 'components/AccessibilityAnnouncements/messageTemplates'
import { CloseExistingSession } from 'components/CloseExistingSession'
import { ErrorBoundary } from 'utils/ErrorBoundary/index'

import { Provider as ApolloProvider } from './config/apollo'
import { GlobalFontStyles } from './config/fonts'
import { NormalizeStyles } from './config/normalize'
import { GlobalStyles } from './config/styles'
import { LoginAppTogglesProvider } from './providers/LoginAppTogglesProvider'
import Router from './router'
import Environment, { EnvironmentProvider } from './utils/environment'
import { Provider as ApolloErrorHandlingProvider } from './utils/error'
import { log } from './utils/log-version'
import { setUdid } from './utils/udid-utils'

type Props = {
  appId: string
  env: Environment
  graphqlUrl: string
  theme: any
  message?: string
}

const Providers = nest(
  EnvironmentProvider,
  ThemeProvider,
  ApolloErrorHandlingProvider,
  ApolloProvider,
)

const Application = (props: Props) => {
  useEffect(() => {
    setUdid()
  }, [])

  useEffect(() => {
    const version: string = process.env.HEAD
    const tag: string = process.env.RELEASE_TAG || process.env.CIRCLE_TAG

    log(version, tag)
  }, [log])

  return (
    <Providers
      key={'app'}
      appId={props.appId}
      env={props.env}
      graphqlUrl={props.env.get('graphql')}
      theme={props.theme}
    >
      <AnalyticsClientProvider
        rudderStack={{
          dataPlaneUrl: props.env.get('rudderStackDataPlaneUrl'),
          writeKey: props.env.get('rudderStackWriteKey'),
        }}
      >
        <EnvironmentHooksProvider env={props.env}>
          <LoginAppTogglesProvider>
            <AccessibilityAnnouncement message={Messages.welcome()} />
            <NormalizeStyles />
            <GlobalStyles />
            <GlobalFontStyles />
            <ErrorBoundary>
              <Router />
            </ErrorBoundary>
            <CloseExistingSession />
          </LoginAppTogglesProvider>
        </EnvironmentHooksProvider>
      </AnalyticsClientProvider>
    </Providers>
  )
}

export default Application
