import { theme } from '@acorns/web-components'
import Styled from 'styled-components'

export const AppleButtonContainer = Styled.div`
  cursor: pointer;
  position: relative;   
`

export const AppleButton = Styled.div`
  text {
    display: none;
  }
`

export const CustomTextWrapper = Styled.div`
  color: ${theme.colors.white};
  display: grid;
  font-family: 'Avenir Next';
  font-size: 14px;
  font-weight: 600;
  grid-gap: 3px;
  grid-template-columns: 24px 1fr;
  left: 50%;
  letter-spacing: 0px;
  line-height: 22px;
  pointer-events: none;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  vertical-align: middle;
`
