import { theme } from '@acorns/web-components'
import Styled from 'styled-components'

export const OrDividerContainer = Styled.div`
  align-items: center;
  display: grid;
  grid-gap: 5px;  
  grid-template-columns: 1fr 24px 1fr;
  height: 24px;
  margin: 25px 0;
  text-align: center;
  width: 100%;
`

export const DividerLine = Styled.div`
  background-color: ${theme.colors.stoneLight};
  height: 1px;
  opacity: 0.35;
  width: 100%;
`

export const DividerOr = Styled.span`
  color: ${theme.colors.stone};
`
