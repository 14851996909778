import React from 'react'

import {
  ButtonStyle,
  ConfirmationModal,
  ModalActionTypes,
} from '@acorns/web-components'
import { Type, useEnv, write } from '@acorns/web-utils'

import AlertBadge from 'assets/icons/alert-badge.png'
import ModalImage from 'assets/images/apple-account-not-linked.png'

export const ERROR_TYPES = {
  ACCOUNT_NOT_LINKED: 'ACCOUNT_NOT_LINKED',
  ACCOUNT_SUSPENDED: 'ACCOUNT_SUSPENDED',
  DEVICE_BLOCKED: 'DEVICE_BLOCKED',
  LOGIN_RATE_LIMITED: 'LOGIN_RATE_LIMITED',
  DEFAULT_ERROR: 'DEFAULT_ERROR',
}

export type ErrorType = typeof ERROR_TYPES[keyof typeof ERROR_TYPES]

type ErrorModalProps = {
  onClose: () => void
  errorType: ErrorType | null
  suspendedToken: string
}

export const SignInWithAppleErrorModal = ({
  onClose,
  errorType,
  suspendedToken = '',
}: ErrorModalProps) => {
  const { env } = useEnv()

  const getModalConfig = () => {
    const configBase = {
      actions: [
        {
          handler: onClose,
          label: 'Go back',
          type: ModalActionTypes.secondary,
        },
      ],
      title: 'Sorry about this',
      message: 'Please wait a few minutes and try again.',
      image: AlertBadge,
      buttonStyle: ButtonStyle.row,
    }

    switch (errorType) {
      case ERROR_TYPES.ACCOUNT_NOT_LINKED:
        return {
          actions: [
            {
              handler: onClose,
              label: 'Got it',
              type: ModalActionTypes.secondary,
            },
            {
              handler: onClose,
              label: 'Log in',
              type: ModalActionTypes.primary,
            },
          ],
          title: "Your Apple account isn't linked to Acorns",
          message:
            "If you're an existing Acorns customer, log in with your email and password.",
          image: ModalImage,
          buttonStyle: ButtonStyle.row,
        }

      case ERROR_TYPES.ACCOUNT_SUSPENDED:
        return {
          actions: [
            {
              handler: () => {
                write(Type.suspendedToken, suspendedToken)
                window.location.href = env.get('suspendedUserRedirectUrl')
                onClose()
              },
              label: 'Upload Documents',
              type: ModalActionTypes.primary,
            },
            {
              handler: onClose,
              label: 'Go back',
              type: ModalActionTypes.secondary,
            },
          ],
          title: 'Acorns account suspended',
          message:
            'Your Acorns account has been temporarily suspended as a security precaution. Please check your email for instructions on restoring access, or contact our Customer Support team for assistance.',
          buttonStyle: ButtonStyle.column,
          image: undefined,
        }

      case ERROR_TYPES.DEVICE_BLOCKED:
        return {
          ...configBase,
          message:
            'For security reasons, we have blocked this device from further login attempts. We sent an email with instructions to regain access to your account.',
        }

      case ERROR_TYPES.LOGIN_RATE_LIMITED:
        return {
          ...configBase,
          message:
            'For your security, we limit login attempts and that limit has been met. Please wait a few minutes and try again.',
        }

      case ERROR_TYPES.DEFAULT_ERROR:
        return configBase

      default:
        return null
    }
  }

  const modalConfig = getModalConfig()

  if (!modalConfig) {
    return null
  }

  return (
    <ConfirmationModal
      actions={modalConfig.actions}
      image={modalConfig.image}
      isOpen={Boolean(modalConfig)}
      message={modalConfig.message}
      title={modalConfig.title}
      buttonStyle={modalConfig.buttonStyle}
      onRequestClose={onClose}
    />
  )
}
