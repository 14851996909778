import React from 'react'

import { useFeature } from '@acorns/web-toggles-react'

import { signInWithAppleWeb } from 'utils/experiments/features'
import { THIRD_PARTY_SIGN_IN } from 'utils/testing/testIds'

import { OrDivider } from './OrDivider'
import { SignInWithApple } from './SignInWithApple'

export const ThirdPartyLogins = () => {
  const [signInWithAppleEnabled] = useFeature(signInWithAppleWeb)

  if (!signInWithAppleEnabled) {
    return null
  }

  return (
    <div data-testid={THIRD_PARTY_SIGN_IN}>
      <OrDivider />
      <SignInWithApple />
    </div>
  )
}
