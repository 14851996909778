import React from 'react'

export const AppleIconSvg = () => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M16.6244 0.21495C16.6733 1.31737 16.2224 2.38708 15.5824 3.18751C14.9114 3.97925 13.8614 4.59687 12.8134 4.59998C12.6946 4.60033 12.5975 4.50811 12.591 4.38953C12.5315 3.31281 13.0658 2.20048 13.6601 1.50587C14.3197 0.731922 15.4267 0.125408 16.3733 0.00192335C16.504 -0.0151283 16.6185 0.0832683 16.6244 0.21495ZM20.0337 6.9487C19.4755 7.34485 18.0714 8.54483 18.0902 10.6176C18.1117 13.1479 19.9457 14.2624 20.5677 14.5672C20.6822 14.6233 20.7461 14.7506 20.7062 14.8716C20.5581 15.3207 20.1488 16.4029 19.377 17.4975C18.555 18.7264 17.6935 19.9277 16.3269 19.9497C15.677 19.9646 15.2382 19.7775 14.7811 19.5826C14.304 19.3792 13.8069 19.1672 13.0288 19.1672C12.2043 19.1672 11.6851 19.3859 11.1845 19.5968C10.7516 19.7792 10.3325 19.9557 9.74174 19.98C8.43941 20.0287 7.44476 18.6685 6.59338 17.4507C4.89245 14.9654 3.56716 10.4458 5.3434 7.37095C6.20396 5.86289 7.77447 4.8912 9.45152 4.86548C10.1903 4.84986 10.899 5.13469 11.5201 5.38435C11.9948 5.5751 12.4183 5.74533 12.7652 5.74533C13.0705 5.74533 13.4826 5.58168 13.9629 5.39096C14.7195 5.09049 15.6453 4.72287 16.5886 4.82232C17.2055 4.83989 18.914 5.05677 20.0982 6.58817C20.1863 6.70214 20.1512 6.86533 20.0337 6.9487Z"
        fill="white"
      />
    </svg>
  )
}
